<template>
  <a-drawer
    @close="$emit('close-drawer')"
    placement="right"
    title="Adding details about Service design aspects in trends 2020"
    :closable="true"
    :visible="drawerVisible"
    :get-container="false"
    :wrap-style="{ position: 'absolute', right: '-40px' }"
    :mask="false"
    wrapClassName="collab-task-detail"
  >
    <div class="assignee-info-details">
      <div class="assignee-info-details__header">
        <p>Assignee</p>
        <p>Due Date</p>
      </div>
      <div class="assignee-info-details__body">
        <div class="user-name">
          <div class="user-img">
            <img src="../../../assets/images/profile-pic.webp" alt="User" />
          </div>
          <h4>Alejandro</h4>
        </div>
        <div class="due-date">
          <h4>Today</h4>
        </div>
        <div class="task-completed">
          <i class="icon icon-tick-mark_circle"></i>
          <p>Mark as completed</p>
        </div>
      </div>
    </div>
    <div class="assignee-info-message">
      <div class="task-description">
        <h4>Description</h4>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet.
        </p>
      </div>
      <div class="user-message">
        <div class="user">
          <div class="user-detail">
            <div class="user-img">
              <img src="../../../assets/images/profile-pic.webp" alt="User" />
            </div>
            <h4>Alejandro</h4>
            <p>1 Day ago</p>
          </div>
          <div class="user-description">
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
          </div>

          <div class="user-detail">
            <div class="user-img">
              <img src="../../../assets/images/profile-pic.webp" alt="User" />
            </div>
            <h4>Alejandro</h4>
            <p>1 Day ago</p>
          </div>
          <div class="user-description">
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
          </div>
          <div class="user-detail">
            <div class="user-img">
              <img src="../../../assets/images/profile-pic.webp" alt="User" />
            </div>
            <h4>Alejandro</h4>
            <p>1 Day ago</p>
          </div>
          <div class="user-description">
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
          </div>
          <div class="user-detail">
            <div class="user-img">
              <img src="../../../assets/images/profile-pic.webp" alt="User" />
            </div>
            <h4>Alejandro</h4>
            <p>1 Day ago</p>
          </div>
          <div class="user-description">
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="assignee-info-footer">
      <div class="user-img">
        <img src="../../../assets/images/profile-pic.webp" alt="User" />
      </div>
      <form action="">
        <input type="text" placeholder="Type your message here." />
      </form>
      <i class="icon icon-send"></i>
    </div>
  </a-drawer>
</template>
<script>
export default {
  props: {
    title: String,
    drawerVisible: Boolean,
  },
};
</script>
<style lang="scss">
.collab-task-detail {
  @include reset-css;

  .ant-drawer-content-wrapper {
    width: 60rem !important;
  }
  .ant-drawer-header {
    padding: 2.5rem 3.5rem;
    border-bottom: none;
  }
  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-wrapper-body {
    padding: 0;
    overflow: hidden;
  }
  .ant-drawer-close {
    top: 1rem;
    right: 2rem;
  }
  .ant-drawer-title {
    font-size: 2.5rem;
    font-family: $font-secondary-bold;
    width: 41rem;
    line-height: 3.2rem;
  }
  .assignee-info-details {
    border-bottom: 0.24rem solid rgba(0, 0, 0, 0.096);
    padding: 2rem 3.5rem;
    padding-top: 1rem;
    &__header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1rem;
      p {
        font-size: 1.6rem;
        font-family: $font-secondary-bold;
        margin-bottom: 0.5rem;
        opacity: 0.8;
        padding-right: 11.4rem;
      }
    }
    &__body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .user-name {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        .user-img {
          width: 2.9rem;
          margin-right: 1rem;
          img {
            width: 100%;
            border-radius: 100%;
          }
        }
        h4 {
          font-size: 1.6rem;
          font-family: $font-secondary;
        }
      }
      .due-date {
        width: 100%;
        h4 {
          font-size: 1.6rem;
          font-family: $font-secondary-bold;
          color: #0ba534;
        }
      }
      .task-completed {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        .icon {
          color: $color-black;
          opacity: 0.3;
          margin-right: 1rem;
          font-size: 2.3rem;
        }
        p {
          font-size: 1.6rem;
          font-family: $font-secondary;
        }
      }
    }
  }
  .assignee-info-message {
    .task-description {
      padding: 2.5rem 3.5rem;
      h4 {
        font-size: 1.9rem;
        font-family: $font-secondary-bold;
        opacity: 0.8;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.6rem;
        font-family: $font-secondary;
        opacity: 0.8;
        line-height: 2.2rem;
      }
    }
    .user-message {
      background-color: #f8f8f8;
      .user {
        padding: 2.5rem 3.5rem;
        padding-top: 0;
        height: 32vh;
        overflow-x: hidden;
        overflow-y: auto;
        .user-detail {
          display: flex;
          align-items: center;
          margin: 1rem 0;
          .user-img {
            width: 2.9rem;
            margin-right: 1rem;
            img {
              width: 100%;
              border-radius: 100%;
            }
          }
          h4 {
            font-size: 1.6rem;
            font-family: $font-secondary-bold;
            margin-right: 1.4rem;
          }
          p {
            font-size: 1.2rem;
            font-family: "Roboto";
          }
        }
        .user-description {
          p {
            font-size: 1.6rem;
            font-family: "Roboto";
            opacity: 0.8;
            line-height: 2.2rem;
          }
        }
      }
    }
  }
  .assignee-info-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 3.5rem;
    background-color: #c2c8d8;
    //height: 9.9rem;
    position: absolute;
    width: 100%;
    bottom: 0;

    .user-img {
      width: 3.6rem;
      margin-right: 1rem;
      img {
        width: 100%;
        border-radius: 100%;
      }
    }
    form {
      width: 100%;
      input {
        border: none;
        width: 100%;
        outline: none;
        padding: 0rem 1.5rem;
        height: 4rem;
        font-size: 1.2rem;
        font-family: $font-secondary;
      }
    }
    .icon {
      color: $color-primary;
      font-size: 3rem;
      margin-left: 1.3rem;
    }
  }
  /*@supports (-moz-appearance:none) {
    .assignee-info-footer { height:8.8rem; }
  }*/
}
</style>

<template>
  <div class="collaboration">
    <div class="task-name">
      <p>Task Name</p>
      <div class="task-name__add">
        <i class="icon icon-add_plus"></i>
        <p>Add task</p>
      </div>
    </div>
    <a-collapse default-active-key="1" :bordered="false">
      <a-collapse-panel key="1" header="To Do's">
        <div class="contant" @click="showDrawer('para-1')">
          <i class="icon icon-tick-mark_circle"></i>
          <p :class="{selected: isSelected('para-1')}">{{ text }}</p>
        </div>
        <div class="contant" @click="showDrawer('para-2')">
          <i class="icon icon-tick-mark_circle"></i>
          <p :class="{'selected': isSelected('para-2')}">{{ text }}</p>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="2" header="Completed">
        <div class="contant" @click="showDrawer('para-3')">
          <i class="icon icon-tick-mark_circle check"></i>
          <p :class="{'selected': isSelected('para-3')}">{{ text }}</p>
        </div>
        <div class="contant" @click="showDrawer('para-4')">
          <i class="icon icon-tick-mark_circle check"></i>
          <p :class="{'selected': isSelected('para-4')}">{{ text }}</p>
        </div>
        <div class="contant" @click="showDrawer('para-5')">
          <i class="icon icon-tick-mark_circle check"></i>
          <p :class="{'selected': isSelected('para-5')}">{{ text }}</p>
        </div>
      </a-collapse-panel>
    </a-collapse>
    <task-detail-drawer
      title="Adding details about Service design aspects in trends 2020"
      :drawer-visible="drawerVisible"
      @close-drawer="closeDrawer"
      >
    </task-detail-drawer>
    
  </div>
</template>

<script>
import TaskDetailDrawer from "./TaskDetailDrawer.vue";

export default {
  components: {
    TaskDetailDrawer,
  },
  data() {
    return {
      drawerVisible: false,
      text: `A dog is a type of domesticated animal. Known for its loyalty and faithfulness, 
             it can be found as a welcome guest in many households across the world.`,
             selectedContent: null,
    };
  },
  methods:{
    showDrawer(content){ 
      this.drawerVisible = true;
      this.selectedContent = content;
    },
    closeDrawer(){
      this.drawerVisible=false;
      this.selectedContent = null;
    },
    isSelected(content){
      return this.selectedContent == content;
    }
  },
};
</script>
<style lang="scss">
@include reset-css;
.collaboration {
  position: relative;
  height: 81vh;
  .task-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.6rem 0;
    border-bottom: 0.24rem solid #7070701f;
    p {
      margin-bottom: 0;
      margin-right: 3rem;
      font-size: 1.8rem;
      opacity: 0.8;
      font-family: $font-secondary-bold;
    }
    &__add {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $color-primary;
      cursor: pointer;
      .icon {
        font-size: 1.6rem;
        margin-right: 1.2rem;
      }
      p {
        margin-bottom: 0;
        font-size: 1.8rem;
        font-family: $font-secondary-bold;
        opacity: 1;
      }
    }
  }
  .ant-collapse-borderless {
    background-color: transparent;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 18px 16px 18px 0px;
    font-size: 1.8rem;
    opacity: 0.8;
    font-family: $font-secondary-bold;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border: none;
  }
  .ant-collapse-content {
    border: 1px solid #70707052 !important;
    border-bottom: none !important;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    left: 10rem;
    font-size: 1.3rem;
  }
  .contant {
    display: flex;
    align-items: center;
    padding: 1rem 3rem !important;
    border-bottom: 1px solid #70707052;
    cursor: pointer;
    .icon {
      margin-right: 2.3rem;
      font-size: 2.2rem;
      opacity: 0.5;
    }
    .check {
      color: $color-primary;
    }
    p {
      margin-bottom: 0;
      opacity: 0.5;
    }
    .selected{
      opacity: 1;
    }
  }
}

</style>
